<template>
  <Create v-if="item.id" :data="item" />
</template>

<script>
import { mapState } from 'vuex';
import Create from '@/views/monitoring/Create';
export default {
  components: { Create },
  computed: {
    ...mapState('monitors', ['item'])
  },
  async created() {
    const monitor = this.item;
    if (!(monitor && (monitor.id === Number(this.$route.params.id)))) { // Fetch
      await this.$store.dispatch('monitors/item', { id: this.$route.params.id });
    }
  }
}
</script>