<template>
  <div class="excel-import">
    <input type="file" ref="fileInput" class="hidden" :accept="accept" @change="handleClick" />
    <div
      @click="$refs.fileInput.click()"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
      class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl"
    >
      <feather-icon icon="UploadIcon" svgClasses="h-12 w-12 stroke-current text-grey" class="block" />
      <div v-if="file">
        <p>
          {{ file.name }} <small class="text-xs">{{ fileSize }}</small>
        </p>
      </div>
      <div v-else>
        <span>{{ label }}</span>
        <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">Browse</span>
      </div>
      <!-- <vs-button type="border" @click.stop="$refs.fileInput.click()">Browse</vs-button> -->
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';

export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    label: {
      type: String,
      default: 'Drop Excel File or '
    },
    accept: {
      type: String,
      default: '.xlsx, .xls'
    }
  },
  data() {
    return {
      excelData: {
        header: null,
        results: null,
        meta: null
      },
      file: null
    };
  },
  computed: {
    fileSize() {
      const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');

      let size = (this.file || {}).size || 0;
      let i = 0;
      while (size > 900) {
        size /= 1024;
        i++;
      }

      return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
    }
  },
  methods: {
    generateData({ header, results, meta }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.meta = meta;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files; // multiple file dragged
      if (files.length !== 1) {
        return this.showError('Please upload single file');
      }
      console.log({ files });
      const rawFile = files[0]; // only use files[0]
      if (!this.isFileAllowed(rawFile)) {
        return this.showError(`Only supports upload ${this.accept} files`);
      }
      this.uploadFile(rawFile);
    },
    readerData(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = e => {
          console.log(e);
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: firstSheetName };
          this.generateData({ header, results, meta });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this.uploadFile(rawFile);
    },
    isFileAllowed(file) {
      return /\.(xlsx|xls|csv|txt)$/.test(file.name);
    },
    uploadFile(file) {
      this.$refs['fileInput'].value = null; // fix can't select the same excel
      this.file = file;
      // this.readerData(file); For demo purpose
    },
    showError(text) {
      this.$vs.notify({
        text,
        color: 'danger',
        time: 150000
      });
    }
  }
};
</script>
