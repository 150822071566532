<template>
  <vx-card>
    <div v-if="form.type === 'file'" class="mb-base">
      <ImportExcel :onSuccess="() => {}" accept=".xlsx, .xls, .csv, .txt" label="Upload File or "></ImportExcel>
    </div>
    <div v-if="form.type === 'raw'" class="mb-base">
      <div class="flex">
        <label class="vs-input--label">URLs</label>
        <div class="con-text-validation span-text-validation vs-input--text-validation-span ml-auto">
          <span class="span-text-validation">
            review or edit the list below. (<strong class="text-primary text-sm">{{ urlsFound }}</strong> urls found)
          </span>
        </div>
      </div>
      <vx-field
        class="mb-0 font-mono"
        as="URLs"
        type="textarea"
        v-model="form.url"
        v-validate="'required'"
        description-text="Lorem ipsum dolor sit"
      />
    </div>
    <div v-if="form.type === 'sitemap'" class="mb-base">
      <vx-field label="Sitemap URL" type="input" class="w-full" v-model="form.url" v-validate="'required'" />
    </div>
    <div class="flex vx-radios w-full sm:w-2/3 mx-auto">
      <label class="flex-1 vx-radio text-xs text-center" :class="{ 'vx-radio--active': form.type === 'file' }" @click="form.type = 'file'">
        <vs-avatar icon-pack="feather" icon="icon-upload" :color="form.type === 'file' ? 'primary' : '#ccc'" size="large" />
        <p class="font-semibold">File Upload</p>
        <p><small>Use a CSV or text file containing urls.</small></p>
      </label>
      <div class="flex-1 vx-radio text-xs text-center" :class="{ 'vx-radio--active': form.type === 'raw' }" @click="form.type = 'raw'">
        <vs-avatar icon-pack="feather" icon="icon-copy" :color="form.type === 'raw' ? 'primary' : '#ccc'" size="large" />
        <p class="font-semibold">Copy/Paste</p>
        <p><small>Type a raw list of urls to monitor.</small></p>
      </div>
      <div class="flex-1 vx-radio text-xs text-center" :class="{ 'vx-radio--active': form.type === 'sitemap' }" @click="form.type = 'sitemap'">
        <vs-avatar icon-pack="feather" icon="icon-book" :color="form.type === 'sitemap' ? 'primary' : '#ccc'" size="large" />
        <p class="font-semibold">Sitemap</p>
        <p><small>Enter URL that has a list of pages of a web site</small></p>
      </div>
    </div>

    <div class="vx-row -mx-2 mt-base">
      <div class="vx-col px-2 mb-3 w-full" :class="{ 'md:w-2/3': form.type !== 'file' }">
        <VxSelectTemplate name="template" v-model="form.template" v-validate="'template'" :disabled="form.id" />
      </div>
      <div v-if="form.type !== 'file'" class="vx-col px-2 mb-3 w-full md:w-1/3">
        <vx-field
          type="select"
          autocomplete
          class="w-full"
          label="Interval"
          v-model="builder.mode"
          description-text="How often captures will be taken."
          :options="{
            value: 'key',
            text: 'text',
            items: builder.intervalOptions
          }"
          :disabled="config.interval === 'disabled'"
        />
      </div>
    </div>

    <div class="mb-3" v-if="form.type !== 'file'">
      <vs-checkbox class="my-5" v-model="isAdvanceOptionEnabled">Advance options</vs-checkbox>
      <transition name="zoom-fade">
        <div v-show="isAdvanceOptionEnabled">
          <div v-if="isAdvanceOptionEnabled" class="vx-row mb-base">
            <div class="vx-col w-1/3">
              <vx-field
                type="switch"
                size="large"
                class="mt-5 w-full"
                label="Capture HTML"
                v-model="form.captureHtml"
                :config="{
                  on: {
                    icon: 'icon-check-circle',
                    text: 'Yes'
                  },
                  off: {
                    icon: 'icon-slash',
                    text: 'No'
                  }
                }"
              />
            </div>
            <div class="vx-col w-1/3">
              <vx-field
                type="switch"
                size="large"
                class="mt-5 w-full"
                label="Capture HAR"
                v-model="form.captureHar"
                :config="{
                  on: {
                    icon: 'icon-check-circle',
                    text: 'Yes'
                  },
                  off: {
                    icon: 'icon-slash',
                    text: 'No'
                  }
                }"
              />
            </div>
            <div class="vx-col w-1/3">
              <vx-field
                type="switch"
                size="large"
                class="mt-5 w-full"
                label="Capture Web Archive"
                v-model="form.captureWarc"
                :config="{
                  on: {
                    icon: 'icon-check-circle',
                    text: 'Yes'
                  },
                  off: {
                    icon: 'icon-slash',
                    text: 'No'
                  }
                }"
              />
            </div>
          </div>
          <VxCronBuilder :builder="builder" v-model="scheduleAndTimeZone" />
        </div>
      </transition>
      <vs-divider class="my-5"></vs-divider>
      <p class="text-sm font-semibold mb-2">Scheduling summary</p>
      <p class="text-sm">
        We'll create a first capture just after the creation of the monitor. Then
        <span class="font-semibold">
          {{ form.schedule | humanDescription(form.timeZone) }}
          <small>({{ timezoneAbbv }})</small> </span
        >.
      </p>
      <vs-divider class="mt-3 mb-5"></vs-divider>
    </div>

    <p class="text-sm text-center mt-10">
      If you need custom adjustments for your monitors, feel free to <a href="#" @click.prevent="$todo">contact our support</a>.
    </p>

    <div class="mt-base mb-3 flex justify-end">
      <vs-button color="success" @click="submit">Save Changes</vs-button>
    </div>
  </vx-card>
</template>

<script>
import Monitoring from '@/entity/Monitoring';
import VxSelectTemplate from '@/components/VxSelectTemplate';
import ImportExcel from '@/components/excel/ImportExcel';
import CronBuilder from '@/components/vx-cron-builder';
import VxCronBuilder from '@/components/vx-cron-builder/VxCronBuilder.vue';

export default {
  props: {
    data: {
      type: Object,
      required: false
    },
    config: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isAdvanceOptionEnabled: Boolean((this.data || {}).id),
      form: new Monitoring(
        (this.data || {}).id
          ? this.data
          : {
              ...this.data,
              type: 'file'
            }
      ),
      builder: new CronBuilder()
    };
  },
  computed: {
    urlsFound() {
      if (this.form.type !== 'raw') return 0;

      // Basic check on url syntax
      const matches = (this.form.url || '').match(/\bhttps?:\/\/\S+/gi);
      if (matches) return matches.length;

      return 0;
    },
    scheduleAndTimeZone: {
      get() {
        if (!this.form.schedule && !this.form.timeZone) return null;
        return {
          schedule: this.form.schedule,
          timeZone: this.form.timeZone
        };
      },
      set(cron) {
        this.form.schedule = cron.schedule;
        this.form.timeZone = cron.timeZone;
      }
    },
    timezoneAbbv() {
      return this.$moment.tz(this.form.timeZone || this.userTimezone).format('z');
    }
  },
  methods: {
    async submit() {
      return await this.$validator.validateAll().then(async valid => {
        if (valid) {
          /* eslint no-unreachable: 0 */
          return this.$todo();
          const { form, testScript, noMonitoringInterval, isTestScriptEnabled } = this;
          if (noMonitoringInterval && !testScript) return;
          const item = await this.$store.dispatch('monitors/update', {
            ...form,
            script: isTestScriptEnabled ? testScript || form.script : '',
            captureScreencast: isTestScriptEnabled ? true : false,
            ...(noMonitoringInterval
              ? {
                  schedule: null,
                  timeZone: null
                }
              : {})
          });
          if (item.id) this.$router.push(`/monitoring/${item.id}`);
        }
      });
    }
  },
  watch: {
    // data: {
    //   handler(data) {
    //     this.form = new Monitoring(data);
    //   },
    //   immediate: true
    // }
  },
  created() {
    this.builder.intervalOptions.shift();
  },
  components: {
    VxSelectTemplate,
    ImportExcel,
    VxCronBuilder
  }
};
</script>
