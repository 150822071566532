<template>
  <section class="monitoring-create-area">
    <div class="vx-row justify-center" v-if="!data.id">
      <div class="vx-col w-full">
        <vx-card>
          <div slot="no-body" class="vx-con-tabs-nav flex sm:justify-between sm:items-center mb-3">
            <ul class="flex">
              <li class="relative">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-file"
                  @click="data.type = 'single'"
                  :class="{ 'vs-button-line--active': data.type === 'single' }"
                >
                  Single Monitor
                </vs-button>
              </li>
              <li class="relative"
                v-if="$hasPermission(permissions.monitors.bulkimport)">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-folder"
                  @click="data.type = 'sitemap'"
                  :class="{ 'vs-button-line--active': data.type !== 'single' }"
                >
                  Bulk Import
                </vs-button>
              </li>
            </ul>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row justify-center">
      <div class="vx-col w-full">
        <SingleMonitorForm v-show="data.type === 'single'" :data="data"></SingleMonitorForm>
        <BulkMonitorForm v-show="data.type !== 'single'" :data="data"></BulkMonitorForm>
      </div>
    </div>
  </section>
</template>

<script>
import Monitoring from '@/entity/Monitoring';
import apiConfig from '@/config/api';
import SingleMonitorForm from '@/views/monitoring/components/SingleMonitorForm';
import BulkMonitorForm from '@/views/monitoring/components/BulkMonitorForm';
//import TestScript from '@/views/monitoring/components/TestScript';
export default {
  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return new Monitoring();
      }
    }
  },
  data() {
    return {
      permissions: apiConfig.permissions
    }
  },
  computed: {},
  methods: {},
  mounted() {},
  watch: {},
  components: {
    SingleMonitorForm,
    BulkMonitorForm,
  //  TestScript
  }
};
</script>
